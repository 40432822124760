import React from 'react'

const Setpoint = ({ activeSetpoint, maxTemp, minTemp, radius, size }) => {
  const mapActiveSetpointToCircle = (val) => {
    const drawMin = -145
    const drawMax = 145

    const currentTemp = val

    // Map currentTemp to the new range
    const drawValue = drawMin + ((currentTemp - minTemp) * (drawMax - drawMin)) / (maxTemp - minTemp)

    return drawValue
  }
  // Calculate the position for the yellow marker (line)
  const rectAngle = mapActiveSetpointToCircle(activeSetpoint)
  const rectX1 = size / 2 + (radius + 20) * Math.cos((rectAngle - 90) * (Math.PI / 180)) // Line start
  const rectY1 = size / 2 + (radius + 20) * Math.sin((rectAngle - 90) * (Math.PI / 180))
  const rectX2 = size / 2 + (radius - 20) * Math.cos((rectAngle - 90) * (Math.PI / 180)) // Line end
  const rectY2 = size / 2 + (radius - 20) * Math.sin((rectAngle - 90) * (Math.PI / 180))

  return <line x1={rectX1} y1={rectY1} x2={rectX2} y2={rectY2} stroke="#ceb041b3" strokeWidth={12} />
}

export default Setpoint
