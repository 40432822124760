import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  detailsContainer: {
    display: 'grid',
    gridTemplateColumns: '48% 48%',
    gridTemplateRows: 'auto auto',
    gridGap: '2em 2%',
    width: '100%',
    height: '100%',
    padding: '2em 0',
    [theme.breakpoints.down('sm')]: {
      padding: '2em'
    }
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.2em',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  labelTypography: { fontSize: '14px', lineHeight: 1, fontWeight: 'bold', margin: 0, color: 'black' }
}))
