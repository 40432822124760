import React from 'react'

const FromToRange = ({ maxTemp, minTemp, size, strokeWidth, range, circumference, radius }) => {
  // Normalize from and to to percentages
  const fromPercentage = (range.from - minTemp) / (maxTemp - minTemp)
  const toPercentage = (range.to - minTemp) / (maxTemp - minTemp)
  // Calculate stroke dash array and offset
  const getDiff = () => {
    const { from, to } = range
    const diff = to - from
    const diffRange = maxTemp - minTemp
    return (diff * 100) / diffRange
  }

  const getDiffOffSet = () => {
    const { from } = range
    const gap = 5
    return from / 2 - gap
  }
  const dashLength = circumference * (toPercentage - fromPercentage) - getDiff()
  const dashOffset = -circumference * fromPercentage + getDiffOffSet()

  return (
    <circle
      cx={size / 2}
      cy={size / 2}
      r={radius}
      stroke="green"
      strokeWidth={strokeWidth}
      fill="transparent"
      strokeDasharray={`${dashLength} ${circumference - dashLength}`}
      strokeDashoffset={dashOffset}
      transform={`rotate(126 ${size / 2} ${size / 2})`}
    />
  )
}

export default FromToRange
