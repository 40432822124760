import React from 'react'
import { Typography } from '@material-ui/core'
import ArrowTemp from './parts/ArrowTemp'
import FromToRange from './parts/FromToRange'
import Setpoint from './parts/Setpoint'
import VelocimeterOutline from './parts/VelocimeterOutline'
import useStyles from './styles'

const BoilerVelocimeter = ({
  range = { from: 0, to: 0 },
  arrowPoint,
  activeSetpoint,
  textHeaderName,
  minTemp = 10,
  maxTemp = 250,
  tempUnit = '°F',
  size = 200
}) => {
  const classes = useStyles(size)

  const strokeWidth = 15
  const radius = (size - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius
  const mainOffSet = Math.round(circumference / 5)

  return (
    <div className={classes.velocimeterContainer}>
      <div className={classes.header}>
        <Typography style={{ fontWeight: 'bold', color: 'black', textAlign: 'center' }}>{textHeaderName}</Typography>
        <div className={classes.headerLine} />
        <div className={classes.headerSetpointContainer}>
          <div className={classes.headerSetpoint}>
            <Typography className={classes.headerSetpointTypography} style={{ fontSize: '22px' }}>
              {activeSetpoint}
            </Typography>
            <Typography className={classes.headerSetpointTypography}>Setpoint</Typography>
          </div>
          <Typography className={classes.headerSetpointTypography}>{tempUnit}</Typography>
        </div>
      </div>
      {/* SVG for the semi-circle */}
      <svg width={size} height={size} style={{ overflow: 'visible' }}>
        {/* Black outline (rainbow shape) */}
        <VelocimeterOutline
          radius={radius}
          strokeWidth={strokeWidth}
          size={size}
          circumference={circumference}
          mainOffSet={mainOffSet}
        />

        {/* Green range */}
        <FromToRange
          maxTemp={maxTemp}
          minTemp={minTemp}
          range={range}
          radius={radius}
          strokeWidth={strokeWidth}
          size={size}
          circumference={circumference}
        />

        {/* Yellow marker */}
        <Setpoint activeSetpoint={activeSetpoint} maxTemp={maxTemp} minTemp={minTemp} radius={radius} size={size} />

        {/* Black Arrow with arrowhead */}
        <ArrowTemp arrowPoint={arrowPoint} maxTemp={maxTemp} minTemp={minTemp} radius={radius} size={size} />
      </svg>

      {/* Value display */}
      <p
        style={{
          position: 'absolute',
          bottom: -12,
          fontSize: 30,
          fontWeight: 'bold',
          color: 'black'
        }}
      >
        {arrowPoint + tempUnit}
      </p>
    </div>
  )
}

BoilerVelocimeter.defaultProps = {
  activeSetpoint: 0,
  arrowPoint: 0, // Range to paint yellow (0-100 scale)
  maxTemp: 250,
  minTemp: 10,
  range: { from: 0, to: 0 }, // Range to paint green (0-100 scale)
  rectPoint: 10, // Initial yellow marker position
  size: 200,
  textHeaderName: ''
}

export default BoilerVelocimeter
