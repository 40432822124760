import React from 'react'

const VelocimeterOutline = ({ radius, strokeWidth, size, circumference, mainOffSet }) => (
  <circle
    cx={size / 2}
    cy={size / 2}
    r={radius}
    stroke="black"
    strokeWidth={strokeWidth}
    fill="transparent"
    strokeDasharray={circumference}
    strokeDashoffset={mainOffSet} // Slight gap to make it almost full-circle
    transform={`rotate(126 ${size / 2} ${size / 2})`} // Rotate to start from top
  />
)

export default VelocimeterOutline
