import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useHistory } from 'react-router-dom'
import SockJsClient from 'react-stomp'
import cookie from 'js-cookie'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import BoilerDataCard from 'components/BoilerDataCard/BoilerDataCard'
import BoilerImage from 'components/BoilerImage/BoilerImage'
import BoilerStatusTabs from 'components/BoilerStatusTabs/BoilerStatusTabs'
import BoilerTempsCard from 'components/BoilerTempsCard/BoilerTempsCard'
import ConnectionBadge from 'components/ConnectionBadge/ConnectionBadge'
import ConnectionStatus from 'components/ConnectionStatus/ConnectionStatus'
import useCallService from 'services/useCallService'
import { isBoilerConnected, valueOrDefault } from 'utils/Utils'
import { useLanguageContext } from 'context/LanguageContext'
import { useMobileContext } from 'context/MobileContext'
import {
  BEARER,
  BOILER_SEND_TO,
  BOILER_SUBSCRIBE_TO,
  CONNECTION_LOCAL,
  CONNECTION_REMOTE,
  DASHBOARD,
  GET_BOILER_URL,
  SOCKET_PATH,
  SOCKET_TRANSPORTS,
  TOKEN
} from 'Constants'
import useStyles from './styles'

const BoilerStatusInfo = ({ boilerId }) => {
  const classes = useStyles()
  const { callBoilerService } = useCallService()
  const [socketRef, setSocketRef] = React.useState(null)
  const [socketConnected, setSocketConnected] = useState(false)
  const [boiler, setBoiler] = useState(null)
  const [boilerStatusData, setBoilerStatusData] = useState(null)
  const [alarmsData, setAlarmsData] = useState([])
  const [boilerExtraPropsData, setBoilerExtraPropsData] = useState(null)
  const history = useHistory()
  const [title, setTitle] = useState(`${process.env.REACT_APP_PAGE_TITLE}`)
  const { language } = useLanguageContext()
  const { isMobile } = useMobileContext()

  useEffect(() => {
    async function fetchBoiler() {
      return callBoilerService('GET', GET_BOILER_URL + boilerId, {}, true)
    }

    fetchBoiler().then((response) => {
      setBoiler(response.data)
      setTitle(`${valueOrDefault(response.data, 'model', '')} | ${process.env.REACT_APP_PAGE_TITLE}`)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handle = setInterval(() => {
      if (boiler && socketRef && socketConnected) {
        const token = cookie.get(TOKEN) ? cookie.get(TOKEN) : ''
        socketRef.sendMessage(BOILER_SEND_TO.replace('{boilerSerialNumber}', boiler.serialNumber), '', {
          language,
          Authorization: `${BEARER} ${token}`
        })
      }
    }, process.env.REACT_APP_MS_FOR_FETCHING_BOILER)

    return () => {
      clearInterval(handle)
    }
  })

  const handleMessage = (msg) => {
    const { alarms, boilerStatusMessage, boilerStatus, boilerExtraProps, boilerModel } = msg
    const updatedBoiler = {
      ...boiler,
      lastSeen: boilerStatusMessage.lastSeen,
      remote: boilerStatusMessage.remote,
      type: boilerStatusMessage.type,
      subType: boilerStatusMessage.subType,
      green: boilerStatusMessage.green,
      model: boilerModel
    }
    setBoiler(updatedBoiler)
    setBoilerExtraPropsData(boilerExtraProps)
    setAlarmsData(alarms)
    const boilerStatusArray = Object.keys(boilerStatus).map((key) => boilerStatus[key])
    setBoilerStatusData(boilerStatusArray.sort((a, b) => a.order - b.order))
    setTitle(`${valueOrDefault(boiler, 'model', '')} | ${process.env.REACT_APP_PAGE_TITLE}`)
  }

  const onConnect = () => {
    setSocketConnected(true)
  }

  const onDisconnect = () => {
    setSocketConnected(false)
  }

  const backToDashboard = () => {
    history.push(DASHBOARD)
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={classes.container}>
        {!isMobile && (
          <div className={classes.statusContainer}>
            <ConnectionStatus
              dotClassName={classes.dot}
              textClassName={classes.connectionText}
              connected={isBoilerConnected(valueOrDefault(boiler, 'lastSeen', null))}
              connectionType={valueOrDefault(boiler, 'remote', null) ? CONNECTION_REMOTE : CONNECTION_LOCAL}
              lastSeen={valueOrDefault(boiler, 'lastSeen', null)}
            />
          </div>
        )}
        <div className={classes.headerContainer}>
          {!isMobile && (
            <div className={classes.title}>
              <ArrowBackIcon className={classes.arrowBack} onClick={backToDashboard} />
              <Typography className={classes.boilerModel}>{valueOrDefault(boiler, 'model', '')}</Typography>
            </div>
          )}
          {isMobile && (
            <div className={classes.mobileBoilerName}>
              <div className={classes.mobileImage}>
                {boiler && <BoilerImage imageUrl={boiler.boilerImage} height={66} genericHeight={54} />}
              </div>
              <div className={classes.mobileBoilerStatus}>
                <Typography className={classes.boilerModel}>{valueOrDefault(boiler, 'model', '')}</Typography>
                <ConnectionStatus
                  dotClassName={classes.dot}
                  textClassName={classes.connectionText}
                  connected={isBoilerConnected(valueOrDefault(boiler, 'lastSeen', null))}
                  connectionType={valueOrDefault(boiler, 'remote', null) ? CONNECTION_REMOTE : CONNECTION_LOCAL}
                  lastSeen={valueOrDefault(boiler, 'lastSeen', null)}
                />
              </div>
            </div>
          )}
          <ConnectionBadge
            badgeClassName={classes.badge}
            connected={isBoilerConnected(valueOrDefault(boiler, 'lastSeen', null))}
            subType={valueOrDefault(boiler, 'subType', null)}
            type={valueOrDefault(boiler, 'type', null)}
            green={valueOrDefault(boiler, 'green', false)}
          />
        </div>

        <div className={classes.dataContainer}>
          <BoilerDataCard boiler={boiler} />
          <BoilerTempsCard
            boilerExtraProps={boilerExtraPropsData}
            boilerType={valueOrDefault(boiler, 'boilerType', null)}
            connected={isBoilerConnected(valueOrDefault(boiler, 'lastSeen', null))}
          />
        </div>
        <div className={classes.tablesContainer}>
          <BoilerStatusTabs boiler={boiler} boilerStatusData={boilerStatusData} alarms={alarmsData} />
        </div>
        {boiler && (
          <SockJsClient
            url={`${process.env.REACT_APP_SOCKET_HOST}${SOCKET_PATH}`}
            topics={[BOILER_SUBSCRIBE_TO.replace('{boilerSerialNumber}', boiler.serialNumber)]}
            options={{ transports: SOCKET_TRANSPORTS }}
            onMessage={handleMessage}
            ref={setSocketRef}
            onConnect={onConnect}
            onDisconnect={onDisconnect}
          />
        )}
      </div>
    </>
  )
}

export default BoilerStatusInfo
