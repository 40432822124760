import React from 'react'

const ArrowTemp = ({ arrowPoint, maxTemp, minTemp, radius, size }) => {
  const mapArrowToCircle = (val) => {
    const drawMin = -145
    const drawMax = 145

    const currentTemp = val

    // Map currentTemp to the new range
    const drawValue = drawMin + ((currentTemp - minTemp) * (drawMax - drawMin)) / (maxTemp - minTemp)

    return drawValue
  }

  const getArrowValue = () => {
    if (arrowPoint > maxTemp) return maxTemp
    if (arrowPoint < minTemp) return minTemp
    return arrowPoint
  }

  // Calculate the angle for the arrow
  const arrowAngle = mapArrowToCircle(getArrowValue())
  const arrowLongMultiplier = 0.6
  const arrowX = size / 2 + radius * Math.cos((arrowAngle - 90) * (Math.PI / 180)) * arrowLongMultiplier
  const arrowY = size / 2 + radius * Math.sin((arrowAngle - 90) * (Math.PI / 180)) * arrowLongMultiplier

  return (
    <>
      <line
        x1={size / 2}
        y1={size / 2}
        x2={arrowX}
        y2={arrowY}
        stroke="black"
        strokeWidth={6}
        strokeLinecap="round"
        markerEnd="url(#arrowhead)" // Add arrowhead
      />
      {/* Arrowhead Marker */}
      <defs>
        <marker id="arrowhead" markerWidth="10" markerHeight="40" refX="0" refY="5" orient="auto" viewBox="0 0 30 30">
          <path d="M0,0 L10,5 L0,10 z" fill="black" />
        </marker>
      </defs>
    </>
  )
}

export default ArrowTemp
