import React from 'react'
import { useIntl } from 'react-intl'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import AlarmsTab from 'components/AlarmsTab/AlarmsTab'
import ArchivesTab from 'components/ArchivesTab/ArchivesTab'
import Loader from 'components/Loader/Loader'
import ManualsTab from 'components/ManualsTab/ManualsTab'
import NotesTab from 'components/NotesTab/NotesTab'
import PhotosTab from 'components/PhotosTab/PhotosTab'
import SearchBar from 'components/SearchBar/SearchBar'
import StatusTab from 'components/StatusTab/StatusTab'
import TabPanel from 'components/TabPanel/TabPanel'
import { isBoilerConnected, valueOrDefault } from 'utils/Utils'
import { useMobileContext } from 'context/MobileContext'
import useStyles from './styles'

const BoilerStatusTabs = ({ boiler, boilerStatusData, alarms }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const [value, setValue] = React.useState('status')
  const [inputValue, setInputValue] = React.useState('')
  const { isMobile } = useMobileContext()

  const handleInputChange = (e) => setInputValue(e.target.value)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.container}>
      <div className={classes.tabsHeader}>
        <Tabs
          classes={{
            root: classes.root,
            indicator: classes.indicator,
            scroller: classes.scroller,
            fixed: classes.fixed
          }}
          value={value}
          onChange={handleChange}
        >
          <Tab
            classes={{ root: classes.tab, wrapper: classes.textWrapper, selected: classes.selected }}
            label={formatMessage({ id: 'BoilerStatus.tab.status' })}
            value={'status'}
          />
          <Tab
            classes={{ root: classes.tab, wrapper: classes.textWrapper, selected: classes.selected }}
            label={formatMessage({ id: 'BoilerStatus.tab.archives' })}
            value={'archives'}
          />
          <Tab
            classes={{ root: classes.tab, wrapper: classes.textWrapper, selected: classes.selected }}
            label={formatMessage({ id: 'BoilerStatus.tab.manuals' })}
            value={'manuals'}
          />
          <Tab
            classes={{ root: classes.tab, wrapper: classes.textWrapper, selected: classes.selected }}
            label={formatMessage({ id: 'BoilerStatus.tab.photos' })}
            value={'photos'}
          />
          <Tab
            classes={{ root: classes.tab, wrapper: classes.textWrapper, selected: classes.selected }}
            label={formatMessage({ id: 'BoilerStatus.tab.alarms' })}
            value={'alarms'}
          />
          <Tab
            classes={{ root: classes.tab, wrapper: classes.textWrapper, selected: classes.selected }}
            label={formatMessage({ id: 'BoilerStatus.tab.notes' })}
            value={'notes'}
          />
        </Tabs>
        {!isMobile && (
          <div className={classes.searchBar}>
            <SearchBar value={inputValue} onChange={handleInputChange} placeholderId={'BoilerStatus.tab.search'} />
          </div>
        )}
      </div>
      <TabPanel className={classes.tabContainer} value={value} index="status">
        <StatusTab
          connected={isBoilerConnected(valueOrDefault(boiler, 'lastSeen', null))}
          boilerStatusData={boilerStatusData}
        />
      </TabPanel>
      <TabPanel className={classes.tabContainer} value={value} index="archives">
        {boiler ? <ArchivesTab boilerId={valueOrDefault(boiler, 'id', null)} searchValue={inputValue} /> : <Loader />}
      </TabPanel>
      <TabPanel className={classes.tabContainer} value={value} index="manuals">
        {boiler ? <ManualsTab boilerModelId={valueOrDefault(boiler, 'modelId', '')} /> : <Loader />}
      </TabPanel>
      <TabPanel className={classes.tabContainer} value={value} index="photos">
        {boiler ? <PhotosTab boilerId={valueOrDefault(boiler, 'id', null)} /> : <Loader />}
      </TabPanel>
      <TabPanel className={classes.tabContainer} value={value} index="alarms">
        {boiler ? <AlarmsTab alarms={alarms} /> : <Loader />}
      </TabPanel>
      <TabPanel className={classes.tabContainer} value={value} index="notes">
        {boiler ? (
          <NotesTab
            boilerId={valueOrDefault(boiler, 'id', null)}
            // onNoteChange={(note) => {
            //   // Aquí puedes manejar los cambios en las notas
            //   console.log('Note changed:', note)
            // }}
          />
        ) : (
          <Loader />
        )}
      </TabPanel>
    </div>
  )
}

export default BoilerStatusTabs
