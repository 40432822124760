import { useEffect, useState } from 'react'
import moment from 'moment'
import cookie from 'js-cookie'
import { ADMIN_ROLE, ENGINEERING_ADMIN_ROLE, PERMISSIONS, REP_ROLE, USER_ADMIN_ROLE } from 'Constants'

moment.suppressDeprecationWarnings = true

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export function sortArray(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export function isBoilerConnected(lastConnection) {
  const diff = lastConnection ? moment().diff(moment(lastConnection), 'seconds') : -1
  return diff <= process.env.REACT_APP_SECONDS_SINCE_LAST_CONNECTION && diff >= 0
}

export function buildAddress(geofence, altGeofence = '') {
  return geofence ? `${geofence.address}, ${geofence.city}, ${geofence.state} ${geofence.zipCode}` : altGeofence
}

export function buildName(firstName, lastName) {
  return firstName ? `${firstName} ${lastName}` : ''
}

export function valueOrDefault(object, field, defaultValue) {
  if (object && object.hasOwnProperty(field)) {
    return object[field]
  }
  return defaultValue
}

export function bytesToMB(bytes) {
  const size = bytes / 1000000
  return parseFloat(size.toString().replace(/([^0-9.])/g, '')).toFixed(2)
}

export function hasPermission(permission) {
  const permissions = cookie.get(PERMISSIONS)
  return permissions ? permissions.includes(permission) : false
}

export function stringLike(a, b) {
  const aAux = a.toLowerCase()
  const bAux = b.toLowerCase()
  return aAux.includes(bAux) || bAux.includes(aAux)
}

export function isRepRole(role) {
  return role === REP_ROLE
}

export function isAdminRole(role) {
  return role === ADMIN_ROLE
}

export function isEngineeringAdminRole(role) {
  return role === ENGINEERING_ADMIN_ROLE
}

export function isUserAdminRole(role) {
  return role === USER_ADMIN_ROLE
}

export function isFullAdmin(role) {
  return isAdminRole(role) || isEngineeringAdminRole(role) || isUserAdminRole(role)
}

export function validateFirmwareVersionMask(value) {
  if (value) {
    if (value.length !== 10 && value.length !== 13) {
      return false
    }
    if (value[1] !== '.') {
      return false
    }
    if (value[10] && value[10] !== '_') {
      return false
    }
    const dotSplit = value.split('.')
    if (Number.isNaN(Number(dotSplit[0]))) {
      return false
    }
    if (dotSplit[1].includes('_')) {
      const underscoreSplit = dotSplit[1].split('_')
      if (Number.isNaN(Number(underscoreSplit[0])) || Number.isNaN(Number(underscoreSplit[1]))) {
        return false
      }
    } else if (Number.isNaN(Number(dotSplit[1]))) {
      return false
    }
  }
  return true
}

export function convertMomentToDate(value) {
  return new Intl.DateTimeFormat('UTC', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).format(value.toDate())
}
