import React from 'react'
import { useIntl } from 'react-intl'
import BoilerTempsRow from 'components/BoilerTempsRow/BoilerTempsRow'
import BoilerVelocimeter from 'components/BoilerVelocimeter/BoilerVelocimeter'
import BoilerVelocimeterDetails from 'components/BoilerVelocimeterDetails/BoilerVelocimeterDetails'
import { valueOrDefault } from 'utils/Utils'
import ICON_BOILER from 'assets/images/icons-boiler.svg'
import ICON_FLAME from 'assets/images/icons-fire-ic.svg'
import ICON_HOME from 'assets/images/icons-home-ic.svg'
import ICON_WATER from 'assets/images/icons-water-ic.svg'
import { BOILER_TYPE } from 'Constants'
import useStyles from './styles'

const BoilerTempsCard = ({ boilerExtraProps, connected, boilerType }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const isBoilerSage = boilerType === BOILER_TYPE.SAGE

  if (!boilerType) {
    return (
      <div className={`${classes.rightBox} ${classes.noBolierType}`}>
        {formatMessage({ id: 'Connection.noConnection' })}
      </div>
    )
  }

  return (
    <div className={`${classes.rightBox} ${isBoilerSage ? classes.rightBoxSage : ''}`}>
      {isBoilerSage ? (
        <>
          <BoilerVelocimeter
            activeSetpoint={valueOrDefault(boilerExtraProps, 'activeSetpoint', 0)}
            textHeaderName={valueOrDefault(boilerExtraProps, 'textHeaderName', '-')}
            range={{
              from: valueOrDefault(boilerExtraProps, 'innerMinTemp', 0),
              to: valueOrDefault(boilerExtraProps, 'innerMaxTemp', 0)
            }}
            arrowPoint={valueOrDefault(boilerExtraProps, 'arrowTemp', 0)}
            minTemp={valueOrDefault(boilerExtraProps, 'minTemp', 10)}
            maxTemp={valueOrDefault(boilerExtraProps, 'maxTemp', 250)}
            tempUnit={valueOrDefault(boilerExtraProps, 'temperatureUnits', '°F')}
          />
          <BoilerVelocimeterDetails boilerExtraProps={boilerExtraProps} />
        </>
      ) : (
        <>
          <BoilerTempsRow
            connected={connected}
            firstLabelId={formatMessage({ id: 'BoilerStatus.data.supply' })}
            secondLabelId={formatMessage({ id: 'BoilerStatus.data.return' })}
            valueUnit={valueOrDefault(boilerExtraProps, 'temperatureUnits', '°F')}
            firstValue={valueOrDefault(boilerExtraProps, 'supplyTemp', '-')}
            secondValue={valueOrDefault(boilerExtraProps, 'returnTemp', '-')}
            active={valueOrDefault(boilerExtraProps, 'radiator', false)}
            heating={valueOrDefault(boilerExtraProps, 'flameNearRadiator', false)}
            primaryImage={ICON_BOILER}
            secondaryImage={ICON_FLAME}
            hasArrows={true}
          />
          <BoilerTempsRow
            connected={connected}
            firstLabelId={valueOrDefault(boilerExtraProps, 'dhw', '')}
            secondLabelId={valueOrDefault(boilerExtraProps, 'recirculation', '')}
            valueUnit={valueOrDefault(boilerExtraProps, 'temperatureUnits', '°F')}
            firstValue={valueOrDefault(boilerExtraProps, 'dhwOutletTemp', '-')}
            secondValue={valueOrDefault(boilerExtraProps, 'recirculationTemp', '-')}
            firstPlaceholderId={formatMessage(
              { id: 'BoilerStatus.data.flow' },
              {
                0: connected ? valueOrDefault(boilerExtraProps, 'flowGpm', '-') : '-'
              }
            )}
            secondPlaceholderId={valueOrDefault(boilerExtraProps, 'dhwComfort', '')}
            active={valueOrDefault(boilerExtraProps, 'faucet', false)}
            heating={valueOrDefault(boilerExtraProps, 'flameNearFaucet', false)}
            primaryImage={ICON_WATER}
            secondaryImage={ICON_FLAME}
            hasArrows={true}
          />
          <BoilerTempsRow
            connected={connected}
            active={valueOrDefault(boilerExtraProps, 'outdoorReset', false)}
            primaryImage={ICON_HOME}
            hasArrows={false}
          />
        </>
      )}
    </div>
  )
}

export default BoilerTempsCard
