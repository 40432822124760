import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  velocimeterContainer: ({ size }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: size,
    height: size,
    boxSizing: 'content-box',
    padding: '2em',
    paddingLeft: '80px'
  }),
  header: {
    position: 'absolute',
    top: 40,
    left: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  headerLine: {
    height: '2px',
    width: '90px',
    backgroundColor: 'gray'
  },
  headerSetpointContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  headerSetpoint: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerSetpointTypography: { fontSize: '14px', lineHeight: 1, fontWeight: 'bold', margin: 0, color: 'black' }
}))
