import React, { useState, useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment'
import classNames from 'classnames'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@mui/material/TextField'
import CustomIcon from 'components/CustomIcon/CustomIcon'
import DeleteNoteModal from 'components/DeleteNoteModal/DeleteNoteModal'
import Loader from 'components/Loader/Loader'
import useCallService from 'services/useCallService'
import ICON_DELETE from 'assets/images/icons-delete.svg'
import { SAVE_BOILER_NOTE_URL, GET_BOILER_NOTES_URL } from 'Constants'
import useStyles from './styles'

const NotesTab = ({ boilerId, initialNote = '' }) => {
  const [note, setNote] = useState(initialNote)
  const [notes, setNotes] = useState([])
  const [isSaving, setSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [serviceError, setServiceError] = useState(false)
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { callBoilerService } = useCallService()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [noteToDelete, setNoteToDelete] = useState(null)
  const parameters = {
    size: process.env.REACT_APP_DASHBOARD_PAGE_SIZE,
    page: 0,
    sortBy: 'createdDate',
    sorting: 'DESC'
  }

  const handleDeleteModal = (noteItem) => {
    setNoteToDelete(noteItem)
    setOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
    setNoteToDelete(null)
  }

  const fetchNotes = useCallback(async () => {
    try {
      setIsLoading(true)

      const urlBoilerNotes = GET_BOILER_NOTES_URL.replace('{boilerId}', boilerId)
      const response = await callBoilerService(
        'GET',
        `${urlBoilerNotes}?size=${parameters.size}&page=${parameters.page}&sortBy=${parameters.sortBy}&sorting=${parameters.sorting}`,
        {},
        true
      )

      setNotes(response.data.data)
    } catch (error) {
      enqueueSnackbar(formatMessage({ id: 'Notes.fetchError' }), { variant: 'error' })
      console.error('Error fetching notes:', error)
    } finally {
      setIsLoading(false)
    }
  }, [enqueueSnackbar, formatMessage])

  useEffect(() => {
    if (boilerId) {
      fetchNotes()
    }
  }, [boilerId, fetchNotes])

  const handleNoteChange = (event) => {
    const { value } = event.target
    if (value.length <= 250) {
      setNote(value)
    }
  }

  const handleSaveNote = async () => {
    try {
      setSaving(true)
      setServiceError(false)

      const saveNoteRequest = {
        boilerId,
        note
      }

      await callBoilerService('POST', SAVE_BOILER_NOTE_URL, { data: saveNoteRequest }, true)

      enqueueSnackbar(formatMessage({ id: 'Notes.saveSuccess' }), {
        variant: 'success'
      })
      // refresh notes
      fetchNotes()
      setNote('')
    } catch (error) {
      setServiceError(true)
      const errorMessage = formatMessage({ id: 'Notes.saveError' })
      enqueueSnackbar(errorMessage, { variant: 'error' })
      console.error('Error saving note:', error)
    } finally {
      setSaving(false)
    }
  }

  const renderNotesList = () => {
    if (isLoading) {
      return <Loader />
    }

    if (notes.length === 0) {
      return <Typography className={classes.noNotes}>{formatMessage({ id: 'Notes.noNotes' })}</Typography>
    }

    return (
      <div className={classes.notesList}>
        {notes.map((noteItem) => (
          <div key={noteItem.id} className={classes.noteItem}>
            <Typography className={classes.noteText}>{noteItem.note}</Typography>
            <div className={classes.noteFooter}>
              <Typography variant="caption" className={classes.noteMetadata}>
                <span>Created by: {noteItem.createdUser}</span>
                <span className={classes.separator}> / </span>
                <span>Created Date: {moment(noteItem.createdDate).format('L LTS')}</span>
              </Typography>
              <CustomIcon
                icon={ICON_DELETE}
                className={classNames(classes.icon, classes.iconPadding)}
                tooltipText={formatMessage({ id: 'Notes.deleteButton' })}
                onClick={() => handleDeleteModal(noteItem)}
              />
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={classes.noteContainer}>
      <TextField
        multiline
        rows={4}
        value={note}
        onChange={handleNoteChange}
        variant="outlined"
        fullWidth
        placeholder={formatMessage({ id: 'Notes.placeholder' })}
        inputProps={{ maxLength: 250 }}
        helperText={`${note.length}/250 ${formatMessage({ id: 'Notes.characters' })}`}
        className={classes.noteField}
        error={serviceError}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleSaveNote}
        disabled={isSaving || note.trim().length === 0}
        className={classes.saveButton}
      >
        {isSaving ? formatMessage({ id: 'Notes.saving' }) : formatMessage({ id: 'Notes.saveButton' })}
      </Button>

      <div className={classes.notesListContainer}>
        <Typography variant="h6" className={classes.notesListTitle}>
          {formatMessage({ id: 'Notes.listTitle' })}
        </Typography>
        {renderNotesList()}
      </div>

      <DeleteNoteModal
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        onSuccess={fetchNotes}
        noteToDelete={noteToDelete}
        boilerId={boilerId}
      />
    </div>
  )
}

export default NotesTab
