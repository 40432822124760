import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import CustomButton from 'components/CustomButton/CustomButton'
import FieldError from 'components/FieldError/FieldError'
import useCallService from 'services/useCallService'
import { DELETE_BOILER_NOTE_URL } from 'Constants'
import useStyles from './styles'

const DeleteNoteModal = ({ open, handleClose, onSuccess, noteToDelete, boilerId }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { callBoilerService } = useCallService()
  const [isLoading, setIsLoading] = useState(false)
  const [serviceError, setServiceError] = useState(false)
  const [serviceErrorMessage, setServiceErrorMessage] = useState('')

  const handleConfirm = async () => {
    setIsLoading(true)
    setServiceError(false)

    const deleteNoteRequest = {
      boilerId,
      noteId: noteToDelete.id
    }

    try {
      await callBoilerService('DELETE', `${DELETE_BOILER_NOTE_URL}`, { data: deleteNoteRequest }, true)
      onSuccess()
      handleClose()
    } catch (error) {
      setServiceError(true)
      setServiceErrorMessage(formatMessage({ id: 'Notes.deleteError' }))
      console.error('Error deleting note:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.dialogTitle}>{formatMessage({ id: 'Notes.deleteButton' })}</DialogTitle>
      <div className={classes.container}>
        <div className={classes.body}>{formatMessage({ id: 'Notes.modal.delete.message' })}</div>
        <FieldError hasError={serviceError} errorMessage={serviceErrorMessage} />
        {isLoading && <LinearProgress className={classes.loader} />}
        <div className={classes.actions}>
          <div className={classes.cancelButton} onClick={handleClose}>
            <Typography>
              <FormattedMessage id={'Commons.button.cancel'} />
            </Typography>
          </div>
          <CustomButton
            labelId={'Commons.button.confirm'}
            disabled={isLoading}
            type={'button'}
            action={handleConfirm}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default DeleteNoteModal
