import React from 'react'
import { useIntl } from 'react-intl'
import { Typography } from '@material-ui/core'
import { valueOrDefault } from 'utils/Utils'
import useStyles from './styles'

const VDetails = ({ value, label, subfix }) => {
  const classes = useStyles()

  return (
    <div className={classes.detailContainer}>
      <div className={classes.labelContainer}>
        <Typography className={classes.labelTypography} style={{ fontSize: '28px' }}>
          {value}
        </Typography>
        <Typography className={classes.labelTypography}>{label}</Typography>
      </div>
      {subfix ? <Typography className={classes.labelTypography}>{subfix}</Typography> : null}
    </div>
  )
}

const BoilerVelocimeterDetails = ({ boilerExtraProps }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  return (
    <div className={classes.detailsContainer}>
      <VDetails
        value={valueOrDefault(boilerExtraProps, 'supplyTemp', '-')}
        label={formatMessage({ id: 'BoilerStatus.data.supply' })}
        subfix={valueOrDefault(boilerExtraProps, 'temperatureUnits', '°F')}
      />

      <VDetails
        value={valueOrDefault(boilerExtraProps, 'returnTemp', '-')}
        label={formatMessage({ id: 'BoilerStatus.data.return' })}
        subfix={valueOrDefault(boilerExtraProps, 'temperatureUnits', '°F')}
      />

      <VDetails value={valueOrDefault(boilerExtraProps, 'deltaTemp', '-')} label={`${'\u25B3'}T`} />

      <VDetails
        value={valueOrDefault(boilerExtraProps, 'flameValue', '-')}
        label={formatMessage({ id: 'BoilerStatus.data.flame' })}
      />
    </div>
  )
}

export default BoilerVelocimeterDetails
