import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  dialogTitle: {
    padding: '24px 24px 8px 24px',
    '& h2': {
      fontSize: 20,
      fontWeight: 'bold',
      color: '#000000'
    }
  },
  container: {
    padding: '0 24px 24px 24px'
  },
  body: {
    marginBottom: 24,
    color: theme.palette.text.secondary,
    fontSize: 14
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 24
  },
  cancelButton: {
    marginRight: 16,
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontWeight: 500
  },
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  loader: {
    marginTop: 16
  }
}))
