import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  noteContainer: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  noteField: {
    marginBottom: 16,
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      backgroundColor: theme.palette.background.paper
    }
  },
  saveButton: {
    marginTop: 16,
    width: 'fit-content',
    alignSelf: 'flex-end'
  },
  notesListContainer: {
    marginTop: 32,
    width: '100%'
  },
  notesListTitle: {
    marginBottom: 16,
    color: theme.palette.text.primary,
    fontSize: 18,
    fontWeight: 600
  },
  notesList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16
  },
  noteItem: {
    padding: 16,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
  },
  noteText: {
    marginBottom: 8,
    fontSize: 14,
    lineHeight: 1.5
  },
  noteDate: {
    color: theme.palette.text.secondary
  },
  noNotes: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic'
  },
  noteFooter: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  noteMetadata: {
    color: theme.palette.text.secondary
  },
  icon: {
    fontSize: 20,
    color: theme.palette.error.main,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    }
  },
  iconPadding: {
    padding: 4
  }
}))
