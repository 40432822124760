import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactSVG } from 'react-svg'
import { Virtuoso } from 'react-virtuoso'
import shortId from 'shortid'
import { Typography } from '@material-ui/core'
import StatusTableRow from 'components/StatusTab/StatusTableRow/StatusTableRow'
import ICON_DISCONNECTED from 'assets/images/icons-disconnected.svg'
import useStyles from './styles'

const StatusTab = ({ connected, boilerStatusData }) => {
  const classes = useStyles()

  /**
   * @description
   * This is a workaround for the following error
   * `ResizeObserver loop completed with undelivered notifications.`
   * Preventing show a webpack error caused by react-virtuoso and webpack in development mode
   * It should be removed after update webpack and react-virtuoso
   */
  useEffect(() => {
    const onError = (e) => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div')
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    }
    window.addEventListener('error', onError)

    return () => {
      window.removeEventListener('error', onError)
    }
  }, [])

  return (
    <>
      {connected && boilerStatusData && (
        <div>
          <Virtuoso
            className={classes.table}
            style={{ width: '100%', height: '300px' }}
            totalCount={boilerStatusData.length}
            itemContent={(index) => (
              <StatusTableRow
                key={shortId.generate()}
                label={boilerStatusData[index].label}
                value={boilerStatusData[index].value}
                secondaryValue={boilerStatusData[index].secondaryValue}
              />
            )}
          />
        </div>
      )}

      {(!connected || !boilerStatusData) && (
        <div className={classes.disconnectedContainer}>
          <ReactSVG src={ICON_DISCONNECTED} className={classes.disconnectedImage} />
          <Typography className={classes.disconnectedText}>
            <FormattedMessage id={'BoilerStatus.status.disconnected'} />
          </Typography>
        </div>
      )}
    </>
  )
}

export default StatusTab
